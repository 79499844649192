import { useCurrencyStore } from '~/stores/currency';
import reviewsConfig from '~/config/reviews';

export function useApiProperties() {
  const { $api } = useNuxtApp();
  const { locale } = useI18n();
  const { getSustainabilityLevel } = useSustainability();

  const currencyStore = useCurrencyStore();

  const dateTime = useDateTime();

  const userCurrency = currencyStore.getCurrentCurrency;

  const defaultQuery = { currency: userCurrency?.code };

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = {};

  const formulateAuthorRank = (numberOfReviews) => {
    if (numberOfReviews <= reviewsConfig.rankLevel.novice) {
      return reviewsConfig.rank.novice;
    }

    if (numberOfReviews <= reviewsConfig.rankLevel.avid) {
      return reviewsConfig.rank.avid;
    }

    return reviewsConfig.rank.globe;
  };

  const calculatePropertyOverallRating = (ratingTotal) => {
    if (ratingTotal <= 0 || ratingTotal > 100) {
      return 0;
    }
    return Number((ratingTotal / 10).toFixed(1));
  };

  const formatPropertyReviewResponse = (reviewResponse) => {
    return {
      pagination: reviewResponse.pagination,
      reviews: reviewResponse.reviews.map((review) => ({
        overallRating: calculatePropertyOverallRating(review?.rating?.overall),
        reviewCard: {
          ownerComment: review?.ownerComment,
          reviewDate: dateTime.formatToHumanReadableShortDate(review?.date, review?.languageCode, 'month|year'),
          reviewText: review?.notes,
        },
        author: {
          gender: `${review?.groupInformation?.groupTypeCode[0].toUpperCase()}${review?.groupInformation?.groupTypeCode.slice(1).toLowerCase()}`,
          nickname: review?.user?.nickname,
          numberOfReviews: review?.user?.numberOfReviews,
          rank: formulateAuthorRank(review?.user?.numberOfReviews),
          countryCode: review?.user?.nationality?.code,
          countryName: review?.user?.nationality?.name,
          ageRange: review?.groupInformation?.age,
        },
      })),
    };
  };

  const getPropertyByPropertyId = async (propertyId) => {
    let propertyInfo = ref(null);

    const callKeySpapi = `getPropertyByPropertyId${locale?.value}${propertyId}${userCurrency?.code}`;
    const endpointSpapi = `property/${propertyId}/`;
    const resultSpapi = ref(await $api.spapi(callKeySpapi, endpointSpapi, defaultHeaders, defaultQuery, defaultParams));
    propertyInfo = resultSpapi?.value?.data || {};

    const callKeyMsApi = `getPropertyByPropertyIdMsApi${locale?.value}${propertyId}${userCurrency?.code}`;
    const endpointMsApi = `property/${propertyId}/`;
    const resultMsApi = ref(await $api.msapi(callKeyMsApi, endpointMsApi, defaultHeaders, defaultQuery, defaultParams));

    const propertyMsApiInfo = toValue(resultMsApi) || {};

    const checkInOutPolicy = {
      checkIn: {
        startsAt: `${propertyMsApiInfo?.houseRules?.checkin?.from}:00`,
        endsAt: `${propertyMsApiInfo?.houseRules?.checkin?.until}:00`,
      },
      checkOut: { endsAt: propertyMsApiInfo?.houseRules?.latestCheckout },
    };
    propertyInfo.policies = {
      ...propertyInfo.policies,
      ...checkInOutPolicy,
      thingsToNote: propertyMsApiInfo?.houseRules?.notes || null,
      cancellationPolicy: propertyMsApiInfo?.houseRules?.cancellationPolicy || null,
    };

    return {
      ...propertyInfo,
      ...{
        sustainability: getSustainabilityLevel(propertyInfo?.sustainability),
        videos: propertyMsApiInfo?.videos,
        recommendedByHw: propertyMsApiInfo?.hostelworldRecommends,
        hoscars: propertyMsApiInfo?.hoscars,
        hostelworldSays: propertyMsApiInfo?.hostelworldSays,
        facilities: propertyMsApiInfo?.facilities,
        // ... add more fields here from HWAPI or MSAPI as needed
      },
    };
  };

  const getPropertyReviews = async (propertyId, filters) => {
    let result = ref(null);

    const callKey = `getPropertyReviews${locale?.value}${propertyId}${userCurrency?.code}`;
    const endpoint = `properties/${propertyId}/reviews/`;

    const callParams = {
      ...defaultParams,
      ...filters,
    };

    result = ref(await $api.hwapi(callKey, endpoint, defaultHeaders, defaultQuery, callParams));

    return formatPropertyReviewResponse(result?.value) || null;
  };

  return {
    getPropertyByPropertyId,
    getPropertyReviews,
  };
}
