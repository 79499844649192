import { useCurrencyStore } from '~/stores/currency';

export function useApiDistricts() {
  const { $api } = useNuxtApp();
  const { locale } = useI18n();
  const { getSustainabilityLevel } = useSustainability();

  const currencyStore = useCurrencyStore();
  const testingStore = useTestingStore();

  const userCurrency = currencyStore.getCurrentCurrency;

  const defaultQuery = { currency: userCurrency?.code };

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = {};

  const BADGETYPES = {
    'breakfast included': { icon: 'breakfast' },
    'free wifi': { icon: 'wi-fi' },
    'sanitisation badge': {
      icon: 'sanitation-badge',
      illustration: 'sanitation',
    },
    'hoscar': {
      icon: 'trophy',
      illustration: 'trophy',
    },
    'experience': {
      icon: 'rocket',
      illustration: 'rocket',
    },
  };

  const BADGETYPES_UNKNOWN = null;
  const getBadgeIcon = (badgeName) => BADGETYPES[badgeName.toLowerCase()] || BADGETYPES_UNKNOWN;

  const updateApiCityProperties = (propertyList) => {
    return propertyList.map((property) => {
      property.description = property.intro;
      property.rating = property.avgRating;
      property.numReviews = property.numberReviews;
      property.distance = property.cityCenterDistance;
      if (property.badges && Array.isArray(property.badges)) {
        property.badges = property.badges.map((badge) => {
          const badgeGraphics = getBadgeIcon(badge.badgeName);
          badge.name = badge.badgeName;
          badge.icon = badgeGraphics.icon;
          badge.illustration = badgeGraphics.illustration;
          badge.showLabel = badge.displayMessage;
          return badge;
        });
      }
      property.minDormPrice = property.sharedMinPrice;
      property.minPrivatePrice = property.privateMinPrice;
      property.urlPath = `hosteldetails.php/${property.urlFriendlyName}/${property.urlFriendlyCity}/${property.id}`;
      property.imgSizes = property.imageSizes?.propertyList;
      property.latitude = property.geoCoordinates?.latitude || null;
      property.longitude = property.geoCoordinates?.longitude || null;
      return property;
    });
  };

  const getDistrictByCityNameAndCountryName = async (
    propertyType,
    countryName,
    cityName,
    districtName,
    pageNum = 1,
    limiter = 30,
    isFeatured = 0,
    filters = null,
  ) => {
    const defaultPaginationValue = 1;
    const page = Number.parseInt(pageNum) || defaultPaginationValue;
    const featured = Number.parseInt(isFeatured) || 0;
    const limit = Number.parseInt(limiter) || 30;

    const internalQuery = {
      limit,
      page,
      featured,
      filters,
      ...defaultQuery,
      origin: 'spapi',
      v: testingStore.hasEnabledFeature('web_newSortOrder') ? 'variation' : 'control',
      ignoreAvailability: testingStore.hasEnabledFeature('static_ignoreAvailability') ? 1 : 0,
    };

    const callKeySpapi = `getDistrictByCityNameAndCountryName${locale?.value}${propertyType}${countryName}${cityName}${districtName}${page}${featured}${limit}${filters}${userCurrency?.code}`;
    const endpointSpapi = `district/${propertyType}/${countryName}/${cityName}/${districtName}/`;
    const resultSpapi = ref(
      await $api.spapi(callKeySpapi, endpointSpapi, defaultHeaders, internalQuery, defaultParams),
    );
    const spapiData = resultSpapi?.value?.data || {};
    spapiData.properties = updateApiCityProperties(spapiData?.properties);

    const callKeyHwApi = `getCityPropertyHwApi${locale?.value}${spapiData?.cityId}${userCurrency?.code}`;
    const endpointHwApi = `cities/${spapiData?.cityId}/properties`;
    const resultHwApi = ref(await $api.hwapi(callKeyHwApi, endpointHwApi, defaultHeaders, defaultQuery, defaultParams));
    const hwapiPropertiesData = toValue(resultHwApi)?.properties || [];

    spapiData.properties?.map((p) => {
      const hwApiProperty = hwapiPropertiesData?.find((property) => property.id === p.id);
      p.sustainability = getSustainabilityLevel(hwApiProperty?.categories);
      return p;
    });

    return spapiData || null;
  };

  return { getDistrictByCityNameAndCountryName };
}
