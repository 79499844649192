import { useCurrencyStore } from '~/stores/currency';

export function useApiTranslator() {
  const { $api } = useNuxtApp();
  const { locale } = useI18n();
  const currencyStore = useCurrencyStore();

  const userCurrency = currencyStore.getCurrentCurrency;

  const defaultQuery = { currency: userCurrency?.code };

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = {};

  const getWorldwideLocationTranslatedById = async (worldwide) => {
    let result = ref(null);

    const internalQuery = {
      worldwide,
      ...defaultQuery,
    };

    const callKey = `getWorldwideLocationTranslatedById${locale?.value}${worldwide}${userCurrency?.code}`;
    const endpoint = 'translator/';

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data?.worldwide || null;
  };

  const getContinentLocationTranslatedById = async (continentId) => {
    let result = ref(null);

    const internalQuery = {
      worldwide: 1,
      continentId,
      ...defaultQuery,
    };

    const callKey = `getContinentLocationTranslatedById${locale?.value}${continentId}${userCurrency?.code}`;
    const endpoint = 'translator/';

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || null;
  };

  const getCountryLocationTranslatedById = async (continentId, countryId) => {
    let result = ref(null);

    const internalQuery = {
      worldwide: 1,
      continentId,
      countryId,
      ...defaultQuery,
    };

    const callKey = `getCountryLocationTranslatedById${locale?.value}${continentId}${countryId}${userCurrency?.code}`;
    const endpoint = 'translator/';

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || null;
  };

  const getAreaLocationTranslatedById = async (continentId, countryId, areaId) => {
    let result = ref(null);

    const internalQuery = {
      worldwide: 1,
      continentId,
      countryId,
      areaId,
      ...defaultQuery,
    };

    const callKey = `getAreaLocationTranslatedById${locale?.value}${continentId}${countryId}${areaId}${userCurrency?.code}`;
    const endpoint = 'translator/';

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || null;
  };

  const getRegionLocationTranslatedById = async (continentId, countryId, regionId) => {
    let result = ref(null);

    const internalQuery = {
      worldwide: 1,
      continentId,
      countryId,
      regionId,
      ...defaultQuery,
    };

    const callKey = `getRegionLocationTranslatedById${locale?.value}${continentId}${countryId}${regionId}${userCurrency?.code}`;
    const endpoint = 'translator/';

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || null;
  };

  const getCityLocationTranslatedById = async (continentId, countryId, cityId) => {
    let result = ref(null);

    const internalQuery = {
      worldwide: 1,
      continentId,
      countryId,
      cityId,
      ...defaultQuery,
    };

    const callKey = `getCityLocationTranslatedById${locale?.value}${continentId}${countryId}${cityId}${userCurrency?.code}`;
    const endpoint = 'translator/';

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || null;
  };

  const getDistrictLocationTranslatedById = async (continentId, countryId, cityId, districtId) => {
    let result = ref(null);

    const internalQuery = {
      worldwide: 1,
      continentId,
      countryId,
      cityId,
      districtId,
      ...defaultQuery,
    };

    const callKey = `getDistrictLocationTranslatedById${locale?.value}${continentId}${countryId}${cityId}${districtId}${userCurrency?.code}`;
    const endpoint = 'translator/';

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || null;
  };

  const getPropertyLocationTranslatedById = async (propertyId) => {
    let result = ref(null);

    const internalQuery = {
      worldwide: 1,
      propertyId,
      ...defaultQuery,
    };

    const callKey = `getPropertyLocationTranslatedById${locale?.value}${propertyId}${userCurrency?.code}`;
    const endpoint = 'translator/';

    result = ref(await $api.spapi(callKey, endpoint, defaultHeaders, internalQuery, defaultParams));

    return result?.value?.data || null;
  };

  return {
    getWorldwideLocationTranslatedById,
    getContinentLocationTranslatedById,
    getCountryLocationTranslatedById,
    getAreaLocationTranslatedById,
    getRegionLocationTranslatedById,
    getCityLocationTranslatedById,
    getDistrictLocationTranslatedById,
    getPropertyLocationTranslatedById,
  };
}
